/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import color from "./color-palette";
import COMMON_COLORS_DEFAULT from "./../common/colors-default";

// -----------------------------------------------------------------------------
// DEFAULT COLORS
// -----------------------------------------------------------------------------

const COLORS_DEFAULT = {
  ...COMMON_COLORS_DEFAULT,

  // Named color definitions shared by each color theme
  mediumGrey:          color.mediumGrey,
  sepia:               color.sepiaBright,

  // Some colors from all themes need to be made available in other themes
  themeTextCopyCharcoal: color.white67,
  themeTextCopyDark:     color.white67,
  themeTextCopyLight:    color.black,
  themeTextCopySepia:    color.sepiaBlack,
  
  themeBackground: {
    charcoal: color.charcoal,
    dark:     color.black,
    light:    color.white,
    sepia:    color.sepiaBright
  },
  
  // Exceptions
  inputBorder:             color.mediumGrey,
  inputBackgroundHover:    color.mediumGrey,
  dropdownBackgroundHover: color.mediumGrey
};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------

export default COLORS_DEFAULT;
