/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2023-present Rakuten Kobo Inc. (https://www.kobo.com)
 */

// -----------------------------------------------------------------------------
// IMPORTS
// -----------------------------------------------------------------------------

// Internal dependencies
import { TrackingError } from './tracking.error';
import { TAG } from './tracking.constants';


// -----------------------------------------------------------------------------
// SERVICE CONFIGURATION
// -----------------------------------------------------------------------------

export const config = [
  'tracking',
  [
    'featureFlag',
    'fetch',
    'localConfig',
    'logger',
    'session'
  ],
  createTrackingService
];

const TrackingEvents = {
  OpenContent: 'OpenContent',
  LeaveContent: 'LeaveContent'
};


// TODO: Move this into common/util (if it is not already there)
function getFormatedDate() {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};


// -----------------------------------------------------------------------------
// KOBO TRACKING SERVICE
// -----------------------------------------------------------------------------

export function createTrackingService(
  featureFlagService, fetchService, localConfig, logger, sessionService
) {
  const appConfig = localConfig.getLocalConfig();
  const path = window.location.pathname;
  const productId = path.substring(path.lastIndexOf('/') + 1);
  const analyticsTrackingUrl = `${appConfig.READINGSERVICES.BASE_URL}/AnalyticsTracking`;
  let serviceContentInfo;
  let serviceStartFile;
  let serviceReadingProgress = 0;
  let isInitialized = false;
  let visibilityChangeHandler;
  let isLeaveContentAlreadyBeingHandled = false;


  // ---------------------------------------------------------------------------
  // FEATURE FLAG SERVICE HELPER
  // ---------------------------------------------------------------------------

  function isFeatureDisabled() {
    const disabledFeatures = featureFlagService.getDisabledFeatures();
    return disabledFeatures.indexOf(featureFlagService.FEATURES.ANALYTICS_TRACKING) > -1;
  }


  function announceDisabledFeature(method) {
    logger.info(`${TAG} Method "${method}()" not executed, because feature "${featureFlagService.FEATURES.ANALYTICS_TRACKING}" is disabled`);
  }


  // ---------------------------------------------------------------------------
  // TRACKING METHODS
  // ---------------------------------------------------------------------------

  function setUp(contentInfo, startFile) {
    // Check if the whole tracking feature is actually enabled
    if (isFeatureDisabled()) {
      announceDisabledFeature('setUp');
      return Promise.resolve();
    };

    if (isInitialized === true) {
      return Promise.resolve();
    }

    if (!productId) {
      return Promise.reject(new TrackingError('NO_PRODUCT_ID'));
    }

    if (contentInfo) {
      serviceContentInfo = contentInfo;
      serviceStartFile = startFile;
      serviceReadingProgress = Math.round(serviceContentInfo?.bookmarkInfo?.progressPercent);
      isInitialized = true;
    } else {
      return sessionService.fetchSession().then(
        sessionId => {
          if (!sessionId) {
            return Promise.reject(new TrackingError('NO_SESSION'));
          }
          return fetchService.fetch({
            url: `${appConfig.READINGSERVICES.BASE_URL}/ReadContent/${productId}`,
            options: {
              credentials: 'include'
            },
            timout: 10000
          }).then(
            response => {
              if (response?.result === 'Success') {
                serviceContentInfo = response;
                isInitialized = true;
              }

              if (response?.result === 'Error') {
                return Promise.reject(new TrackingError('FAULTY_PAYLOAD', { detail: { response } }));
              }

              return Promise.reject(new TrackingError('FAILED_REQUEST', { detail: { response } }));
            }
          ).catch(
            error => {
              if (error instanceof TrackingError) {
                return Promise.reject(error);
              }

              return Promise.reject(new TrackingError('FAILED_REQUEST'), { detail: { parent: error } });
            }
          )
        }
      );
    }
  }


  function handleOpenContent() {
    // Check if the whole tracking feature is actually enabled
    if (isFeatureDisabled()) {
      announceDisabledFeature('handleOpenContent');
      return Promise.resolve();
    };

    if (!productId) {
      return Promise.reject(new TrackingError('NO_PRODUCT_ID'));
    }

    // Register an event handler that will listen for whether the tab/window
    // will be put into the background, i.e. window is minified, tab is changed
    // etc.
    visibilityChangeHandler = handleLeaveContent.bind(null, performance.now());
    window.addEventListener('visibilitychange', visibilityChangeHandler);
    window.addEventListener('beforeunload', visibilityChangeHandler);
    isLeaveContentAlreadyBeingHandled = false;

    const shouldSendTrackingEvent = !!serviceContentInfo;
    if (shouldSendTrackingEvent === true) {
      const bookmark = typeof serviceContentInfo?.bookmarkInfo?.bookmark === 'object'
        ? serviceContentInfo?.bookmarkInfo?.bookmark
        : (
          typeof serviceContentInfo?.bookmarkInfo?.bookmark === 'string' && serviceContentInfo?.bookmarkInfo?.bookmark !== ''
            ? JSON.parse(serviceContentInfo?.bookmarkInfo?.bookmark)
            : null
        );

      return fetchService.fetch({
        data: JSON.stringify({
          eventType: TrackingEvents.OpenContent,
          timeStamp: getFormatedDate(),
          attributes: {
            startFile: bookmark ? bookmark.ContentSource : serviceStartFile,
            startSpan: bookmark ? bookmark.Value : 'kobo.1.1',
            volumeid: productId
          },
          metrics: {
            progress: Math.round(serviceContentInfo?.bookmarkInfo?.progressPercent)
          }
        }),
        options: {
          credentials: 'include',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          keepalive: true,
          method: 'POST',
          responseType: 'json'
        },
        url: analyticsTrackingUrl,
        timout: 10000
      }).then(
        () => {
          logger.info(`${TAG} Successfully sent OpenContent event`);
        }
      ).catch(
        error => {
          if (error instanceof TrackingError) {
            return Promise.reject(error);
          }

          return Promise.reject(new TrackingError('FAILED_REQUEST'), { error });
        }
      );
    }
  }


  function handleLeaveContent(startTime) {
    if (isLeaveContentAlreadyBeingHandled === true) {
      return;
    }
    isLeaveContentAlreadyBeingHandled = true;

    const bookmark = typeof serviceContentInfo?.bookmarkInfo?.bookmark === 'object'
      ? serviceContentInfo?.bookmarkInfo?.bookmark
      : (
        typeof serviceContentInfo?.bookmarkInfo?.bookmark === 'string' && serviceContentInfo?.bookmarkInfo?.bookmark !== ''
          ? JSON.parse(serviceContentInfo?.bookmarkInfo?.bookmark)
          : null
      );
    const endTime = performance.now();
    const timeElapsed = Math.round((endTime - startTime) / 1000);
    const pagesTurned = Math.round(timeElapsed / 60);

    const data = JSON.stringify({
      eventType: TrackingEvents.LeaveContent,
      timeStamp: getFormatedDate(),
      attributes: {
        startFile: bookmark ? bookmark.ContentSource : serviceStartFile,
        startSpan: bookmark ? bookmark.Value : 'kobo.1.1',
        volumeid: productId
      },
      metrics: {
        progress: serviceReadingProgress,
        secondsRead: timeElapsed,
        pagesTurned: pagesTurned
      }
    });

    // Use sendBeacon for sending data instead of fetch,
    // this ensures that the data is sent reliably
    // even when the page is about to be unloaded
    const blob = new Blob([ data ], { type: 'application/json' });
    navigator.sendBeacon(analyticsTrackingUrl, blob);

    // We remove the event listener, because we are done with tracking.
    // Whenever handleOpenContent is called for the next time the content
    // becomes active, a new listener is registered
    window.removeEventListener('visibilitychange', visibilityChangeHandler);
    window.removeEventListener('beforeunload', visibilityChangeHandler);
    visibilityChangeHandler = null;
  }


  function updateProgress(value) {
    if (isFeatureDisabled()) {
      announceDisabledFeature('updateProgress');
      return;
    }

    serviceReadingProgress = Math.round(value);
  }


  // ---------------------------------------------------------------------------
  // PUBLIC API
  // ---------------------------------------------------------------------------

  return {
    setUp,
    handleOpenContent,
    updateProgress
  };
};
