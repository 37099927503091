/**
 * ████████╗ ██████╗ ██╗     ██╗███╗   ██╗ ██████╗
 * ╚══██╔══╝██╔═══██╗██║     ██║████╗  ██║██╔═══██╗
 *    ██║   ██║   ██║██║     ██║██╔██╗ ██║██║   ██║
 *    ██║   ██║   ██║██║     ██║██║╚██╗██║██║   ██║
 *    ██║   ╚██████╔╝███████╗██║██║ ╚████║╚██████╔╝
 *    ╚═╝    ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═══╝ ╚═════╝
 *
 * (c) Copyright 2021-present Rakuten Kobo Inc. (https://www.kobo.com)
 */


// =============================================================================
// DEFAULT VARIABLES
// =============================================================================

const BASIC_FONT_SIZE = 10;
const BASIC_UNIT = .8;  // => .8rem, because default font size = 10px

const VARIABLES = {

  // Exported because it might be needed for calculations
  _BASIC_FONT_SIZE: BASIC_FONT_SIZE,

  // Exported because it might be needed for calculations
  _BASIC_UNIT: BASIC_UNIT,

  // ---------------------------------------------------------------------------
  // BASIS
  // ---------------------------------------------------------------------------

  animationDuration: '.3s',
  animationEasing: 'ease-in-out',

  basicUnit: `${BASIC_UNIT}rem`,
  basicUnitRaw: BASIC_UNIT,
  borderRadius: `${BASIC_UNIT * 2}rem`,


  // ---------------------------------------------------------------------------
  // COMPONENTS
  // ---------------------------------------------------------------------------

  // The values listed here in the COMPONENTS section might
  //  a) be used by other components for calculation.
  //  b) be customized/different for other themes.

  footer: {
    height: `${BASIC_UNIT * 22.25}rem`,
    heightReduced: `${BASIC_UNIT * 17}rem`,
    maxWidth: `${BASIC_UNIT * 128}rem`
  },

  headerBar: {
    default: `${BASIC_UNIT * 7}rem`,
    large: `${BASIC_UNIT * 8}rem`
  },

  iconSize: {
    small: `${BASIC_UNIT * 2.25}rem`,
    default: `${BASIC_UNIT * 3}rem`,
    large: `${BASIC_UNIT * 4}rem`,
    xlarge: `${BASIC_UNIT * 6}rem`
  },
  iconSizeMarginCorrection: {
    small: `-${BASIC_UNIT * 1.875}rem`,
    default: `-${BASIC_UNIT * 1.5}rem`,
    large: `-${BASIC_UNIT}rem`,
    xlarge: `-${BASIC_UNIT}rem`
  },
  iconSizePadding: {
    small: `${BASIC_UNIT * 1.875}rem`,
    default: `${BASIC_UNIT * 1.5}rem`,
    large: `${BASIC_UNIT}rem`,
    xlarge: `${BASIC_UNIT}rem`
  },
  iconButtonSizePadding: {
    small: `${BASIC_UNIT * 1.275}rem`,
    default: `${BASIC_UNIT}rem`,
    large: `${BASIC_UNIT * 0.8 }rem`,
    xlarge: `${BASIC_UNIT * 0.8 }rem`,
    navigationControls: `${BASIC_UNIT * 0.45 }rem`,
    activeWithBorder: `${BASIC_UNIT * 0.75 }rem`
  },
  iconSizeContainer: `${BASIC_UNIT * 5}rem`,
  iconSizeTouch: `${BASIC_UNIT * 6}rem`,
  iconSizeTouchBigger: `${BASIC_UNIT * 9}rem`,

  iconSizeSelectionControl: `${BASIC_UNIT * 5.5}rem`,
  iconPaddingSelectionControl: `${BASIC_UNIT}rem`,
  iconPaddingNavigationControls: `${BASIC_UNIT * 0.5}rem`,

  lineItemHeight: {
    default: `${BASIC_UNIT * 6}rem`,
    large: `${BASIC_UNIT * 8}rem`
  },

  popUpVerticalMargin: {
    default: `${BASIC_UNIT * 2}rem`,
    small: `${BASIC_UNIT * .4}rem`
  },

  resellerLogoSize: {
    small: `${BASIC_UNIT * 4}rem`,
    medium: `${BASIC_UNIT * 6}rem`,
    large: `${BASIC_UNIT * 8}rem`,
    xlarge: `${BASIC_UNIT * 10}rem`
  },



  // ---------------------------------------------------------------------------
  // MISC.
  // ---------------------------------------------------------------------------

  sidebarWidth: '28rem',

  padding:`${BASIC_UNIT}rem`,
  paddingXsmall: `${BASIC_UNIT}rem`,
  paddingSmall: `${BASIC_UNIT * 2}rem`,
  paddingMedium: `${BASIC_UNIT * 3}rem`,
  paddingLarge: `${BASIC_UNIT * 4}rem`,
  paddingXlarge: `${BASIC_UNIT * 6}rem`,

  zIndexDefault: 'auto',
  zIndexNavBars: 500,
  zIndexBarsActions: 1000,
  zIndexBars: 10000,
  zIndexModalBlocker: 20000,
  zIndexPopUps: 30000,
  zIndexDialogs: 30000

};


// -----------------------------------------------------------------------------
// EXPORTS
// -----------------------------------------------------------------------------


export default VARIABLES;
export {
  BASIC_FONT_SIZE,
  BASIC_UNIT
};
