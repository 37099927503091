import color from "./color-palette";

const COLORS_LIGHT = {

  primary:                   color.tolinoBlue,
  primaryDisabled:           color.cloudGrey,
  primaryHover:              color.tolinoBlueBright,
  primarySelected:           color.tolinoBlueLight,
  primaryPressed:            color.tolinoBlueMedium,

  icon:                      color.slateGrey,
  iconDisabled:              color.black42,
  iconHover:                 color.black04,
  iconSelected:              color.black12,
  iconPressed:               color.slateGrey16,

  label:                     color.quickSilver,
  divider:                   color.whiteWarm,
  textHeadline:              color.nightGrey,
  text:                      color.slateGrey,
  background:                color.white,
  backgroundUI:              color.altWhite,

  highlight:                 color.lightYellow,
  highlightSearch:           color.tolinoBlueLight,
  highlightAlpha:            color.gentleYellow,
  alert:                     color.orangeRed,

  // Exceptions
  iconActive:                color.tolinoBlue,
  tabsBackground:            color.whiteWarm,          // color of light/divider
  tabBackground:             color.whiteWarm,
  tabBackgroundHover:        color.white,
  tabBackgroundActive:       color.white,
  tabBackgroundActiveHover:  color.tolinoBlueBright,   // color of light/primaryHover

  lightBackgroundUI:         color.altWhite,           // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIcon:                 color.slateGrey,          // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconDisabled:         color.black42,            // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconHover:            color.black04,            // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconSelected:         color.black12,            // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightIconPressed:          color.slateGrey16,        // for theme independent iconButtons (Zoom UI -Floating -Navbar)
  lightPrimary:              color.tolinoBlue,         // for theme independent iconButtons (Zoom UI -Floating -Navbar)

  iconDarkThemeHover:        color.white08,
  iconLightThemeHover:       color.black04
};

export default COLORS_LIGHT;
